import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import LayoutEt from "../../components/LayoutEt"
import HeaderEt from '../../components/HeaderEt'
import Seo from "../../components/Seo"

import ChartArea from '../../images/icons/chart-area.svg'
import Cubes from '../../images/icons/cubes.svg'
import ShippingFast from '../../images/icons/shipping-fast.svg'
import ProjectDiagram from '../../images/icons/project-diagram.svg'

import Sliders from '../../images/icons/sliders.svg'
import Robot from '../../images/icons/robot.svg'
import BusinessTime from '../../images/icons/business-time.svg'
import Recycle from '../../images/icons/recycle.svg'
import Seedling from '../../images/icons/seedling.svg'
import Globe from '../../images/icons/globe.svg'
import Sirkel from '../../images/icons/sirkel.svg'
import BezierCurve from '../../images/icons/bezier-curve.svg'
import RTLogo from '../../images/rt-logo.svg'
import ContactLauriEt from "../../components/ContactLauriEt"


const OurStory = () => {
  return (
    <LayoutEt>

      <Seo title="Ettevõttest" description="Puidust elementmajade tootja" lang="et" />
      <HeaderEt />

      <div className="container-base">
        <div className="section-1-2-center">
          <h1 className="title-xl wel-mb-s">Ettevõttest</h1>
          <p className="body-l">Kuidas meie lugu alguse sai ja kuidas plaanime ehitustööstust innoveerida.</p>
        </div>
      </div>

      <div className="wel-mb-l">
        <StaticImage
          src="../../images/our-story/team.png"
          quality={100}
          formats={["auto", "webp", "avif"]}
          alt="Welement Team"
          loading="eager"
          placeholder="blurred"
          className="w-full"
        />
      </div>

      <div className="container-base">
        <div className="wel-mb-l">
          <div className="section-1-2-center">
            <img src={ChartArea} alt="Icon" className="h-20 mx-auto wel-mb-xs mr-4 inline-block" />
            <img src={Cubes} alt="Icon" className="h-20 mx-auto wel-mb-xs inline-block" />
            <h3 className="title-m wel-mb-s">Mis on ehituses valesti?</h3>
            <p className="body-l">Ehitussektori murekoht on madal produktiivsus. Järjest keerulisem on leida
              kvalifitseeritud tööjõudu ning usaldusväärseid partnereid, kes suudaks tähtaegadest
              kinni pidada ja tagada kaasaegsetele nõuetele vastava ehituskvaliteedi.
              Omades suurte projektide ehituskogemust nii Eestis kui ka välismaal küsisime
              endalt mõned aastad tagasi — kuidas me saaksime ehitusprotsessi paremaks
              muuta?</p>
          </div>
        </div>

        <div className="wel-mb-l">
          <div className="section-1-2-center">
            <img src={ShippingFast} alt="Icon" className="h-20 mx-auto wel-mb-xs mr-4 inline-block" />
            <img src={ProjectDiagram} alt="Icon" className="h-20 mx-auto wel-mb-xs inline-block" />
            <h3 className="title-m wel-mb-s">Algus</h3>
            <p className="body-l">Teadsime, et harjumuspäraseid tegevusi korrates ei tasu oodata muutusi.
              Kuna ehituse maksumuse kujundavad peamiselt aeg ning kasutatavad
              materjalid ja viimase pealt järeleandmisi teha ei saa, otsustasime
              paremate tulemuste saavutamiseks muuta aja ja materjalide kasutuse
              võimalikuks tõhusaks.</p>
          </div>
        </div>

      </div>

      <div className="bg-welement-pink wel-mb-l">


        <div className="container-base">
          <div className="section-1-2-center wel-mb-l">
            <h2 className="title-l">Mis on lahendus?</h2>
          </div>
          <div className="flex justify-start wel-mb-l">
            <div className="w-full md:w-2/3 lg:w-1/2 text-left bg-welement-white p-16 rounded">
              <div className="wel-mb-s">
                <img src={Sliders} alt="Icon" className="h-16 mx-auto wel-mb-xs mr-4 inline-block" />
                <img src={Robot} alt="Icon" className="h-16 mx-auto wel-mb-xs mr-4 inline-block" />
                <img src={BusinessTime} alt="Icon" className="h-16 mx-auto wel-mb-xs inline-block" />
              </div>

              <h2 className="title-xs mb-2">Tehaseline ehitus</h2>
              <p className="body-l">Me usume, et luues suure osa ehitusobjekti väärtusest tehaselises
                keskkonnas saame kõige hõlpsamini kontrollida aja- ja materjalide kulu,
                tööohutust ja -kvaliteeti ning sisendenergiat.</p>
            </div>
          </div>
          <div className="flex justify-end wel-mb-l">
            <div className="w-full md:w-2/3 lg:w-1/2 text-left bg-welement-white p-16 rounded">
              <div className="wel-mb-s">
                <img src={Recycle} alt="Icon" className="h-16 mx-auto wel-mb-xs mr-4 inline-block" />
                <img src={Seedling} alt="Icon" className="h-16 mx-auto wel-mb-xs mr-4 inline-block" />
                <img src={Globe} alt="Icon" className="h-16 mx-auto wel-mb-xs inline-block" />
              </div>
              <h2 className="title-xs mb-2">Puit kui tulevikumaterjal</h2>
              <p className="body-l">Ehitusprotsessi tõhususe ja produktiivsuse tõstmise kõrval on väga oluline
                vähendada selle ökoloogilist jalajälge. Puidu kasutamine ehitusmaterjalina
                on hea võimalus, mille kaudu panustada CO2 vähendamisesse keskkonnas.</p>
            </div>
          </div>

          <div className="flex justify-start wel-mb-l">
            <div className="w-full md:w-2/3 lg:w-1/2 text-left bg-welement-white p-16 rounded">
              <div className="wel-mb-s">
                <img src={BezierCurve} alt="Icon" className="h-16 mx-auto wel-mb-xs mr-4 inline-block" />
                <img src={Sirkel} alt="Icon" className="h-16 mx-auto wel-mb-xs inline-block" />
              </div>
              <h2 className="title-xs mb-2">Ehitusprotsess</h2>
              <p className="body-l">Ühendades oma teadmised ja kogemused viimaste tehnoloogiliste
                lahendustega oleme Welementi meeskonnaga loonud kõige kaasaegsema
                majatehase, millega panustame ehitusprotsessi paremaks muutmisesse.</p>
            </div>
          </div>
        </div>

      </div>

      <div className="container-base">
        <div className="mx-auto text-center w-3/4">
          <img src={RTLogo} alt="Rand &amp; Tuulberg" className="h-10 mx-auto mb-8" />

          <p className="body-l wel-mb-m">Welement kuulub Rand &amp; Tuulberg gruppi, mis on tegelenud ehitusmaterjalide tootmise, kinnisvara arendamise ja ehituse peatöövõtuga juba üle 25 aasta</p>

        </div>
      </div>

      <ContactLauriEt />

    </LayoutEt>
  )

};

export default OurStory;
