/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import CookieConsent from "react-cookie-consent"


import FooterEt from "./FooterEt"

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}

const LayoutEt = ({ children }) => {

  return (
    <>
      <div>
        <CookieConsent
          buttonText="Accept"
          declineButtonText="Decline"
          cookieName="gatsby-gdpr-google-tagmanager"
          acceptOnScroll={true}
          acceptOnScrollPercentage={50}
          disableStyles={true}
          buttonClasses="btn-sm btn-black-outline !inline-block float-right"
          containerClasses="fixed bottom-0 left-0 z-50 w-full bg-welement-white px-8 py-4 grid items-center grid-cols-4 gap-2"
          contentClasses="inline-block leading-none col-span-3">
          <span className="body-s block mb-0">This site uses cookies to improve your experience. </span>
          <span className="body-s opacity-50">You may opt-out of your consent at any time by changing the settings of the device you are using and deleting your saved cookies.</span>
        </CookieConsent>
        <main>{children}</main>
        <FooterEt />
      </div>
    </>
  )
}

LayoutEt.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutEt


